.dashboard_header_search_input {
  border: none; /* Removes the default border */
  outline: none;
}
/* .dashboard_header_search_input::placeholder {
  color: #fff;
} */

.dashboard_header_search_input:focus,
.dashboard_header_search_input:hover {
  border: none; /* Removes the border on focus or hover */
  outline: none; /* Removes the outline on focus or hover */
}
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
  background: #fbdede;
  border-radius: 8px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #ffc8c8;
}

/* Firefox-specific scrollbar styling */
* {
  scrollbar-width: thin;
  scrollbar-color: #fbdede #f1f1f1;
}
